<template>
  <div id="app" :class="theme">
    <div class="header-container bgc1 row">
      <div class="header-logo pointer">
        <img :src="require(`@/assets/img/logo-${theme}.png`)" width="112" height="32"/>
      </div>
      <div class="bit-header-tab">
        <header-tab ref="headerTab" :theme="theme"/>
      </div>
      <div class="bit-header-options">
        <div class="row gap4 fc1 pointer" @click="goCaseApply">
          <img :src="require(`@/assets/img/icon-case-${theme}.png`)"/>
          <div>{{ this.$t("tab.case") }}</div>
        </div>
        <el-popover
          :popper-class="'language-popover shadow-'+theme"
          placement="bottom-start"
          width="88"
          :visible-arrow="false"
          trigger="hover">
          <img class="pointer popover-img" :src="require(`@/assets/img/icon-language-${theme}.png`)" slot="reference"/>
          <div :class="'language-box pointer '+theme ">
            <div class="hc1" @click="dropdownHandler('zh')">{{ this.$t("tab.zh") }}</div>
            <div class="hc1" @click="dropdownHandler('en')">{{ this.$t("tab.en") }}</div>
          </div>
        </el-popover>
        <img class="pointer" :src="require(`@/assets/img/icon-theme-${theme}.png`)" @click="changeTheme"/>
      </div>
    </div>
    <div class="main-container" ref="mainContainer">
      <router-view @scrollToBottom="scrollToBottom"  @scrollToTop="scrollToTop"/>
    </div>

  </div>
</template>

<script>
import HeaderTab from "@/components/HeaderTab";
export default {
  name: "app",
  components: {
    HeaderTab,
  },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
        if(t == null) t = 'dark'
      }
      return t
    },
  },
  data() {
    return {
      language: localStorage.getItem("lang") || "zh",
      loginDia: false,
      accessToken: localStorage.getItem("accessToken"),
      username: "",
      showLanguage: false
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo")) {
      this.username = JSON.parse(localStorage.getItem("userInfo")).name;
    }
  },
  methods: {
    changeTheme(){
      localStorage.setItem("theme", this.theme=="dark"?"light":"dark")
      this.$store.commit("updateTheme",this.theme=="dark"?"light":"dark");
    },
    goCaseApply(){
      this.$router.push("/assist");
    },
    dropdownHandler(language) {
      localStorage.setItem("lang", language);
      this.$i18n.locale = language;
      // window.location.reload();
    },
    scrollToBottom() {
      var container = this.$refs.mainContainer;
      container.scrollTop = container.scrollHeight;
    },
    scrollToTop() {
      var container = this.$refs.mainContainer;
      container.scrollTop = 0;
    },
    goLogin() {
      this.$router.push("login");
    },
    handleClose() {
      this.loginDia = false;
    },
    wxLoginSuccess() {
      this.loginDia = false;
      this.accessToken = localStorage.getItem("accessToken");
      this.username = JSON.parse(localStorage.getItem("userInfo")).name;
      // 设置登录状态
      localStorage.setItem("isLogin",1);
    },
    logout() {
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        localStorage.clear();
        this.accessToken = null;
        localStorage.setItem("isLogin",0);
        this.username = "";
        this.$router.push("/");
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/style.css";
#app {
  width: 100%;
  min-width: 1440px;
  overflow-x: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-container {
    padding: 0 40px;
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    .bit-header-tab {
      margin:0 40px;
    }
    .bit-header-options {
      position: absolute;
      right: 40px;
      display: flex;
      gap: 40px;
      align-items: center;
      img{
        width: 24px;
        height: 24px;
      }
      .popover-img{
        position: relative;
        top: 2.5px;
      }
    }
  }
  .main-container {
    flex: 1;
    display: flex;
    overflow-y: scroll;
    min-width: 1280px;
  }
  /deep/.el-dropdown.dropdown-img {
    width: 22px;
    height: 21px;
    margin-right: 8px;
  }
}
.language-box{
  width: 88px !important;
  height: 88px !important;
  box-sizing: border-box;
  padding: 4px 0;
  div{
    height: 40px;
    text-align: left;
    line-height: 40px;
    font-size: 14px;
    padding-left: 16px;
  }
}
</style>
