import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    headerTab: 0,
    language: 'chinese',
    theme: null
  },
  mutations: {
    updateLogStatus(state, isLogin) {
      state.isLogin = isLogin
    },
    updateHeaderTab(state, headerTab) {
      state.headerTab = headerTab
    },
    updateTheme(state, theme) {
      state.theme = theme

    },
    updateLanguage(state, language) {
      state.language = language
    }
  },
  actions: {},
  modules: {},
});
