<template>
  <div class="header-tab">
    <el-menu class="bit-menu" :class="'bit-menu-'+theme" mode="horizontal" @select="handleSelect"
             menu-trigger="hover"
             :background-color="theme=='dark'?'#121212':'#FFFFFF'"
             :active-text-color="theme=='dark'?'#FFFFFF':'#000000E6'"
             :text-color="theme=='dark'?'#FFFFFF':'#000000E6'">
      <el-menu-item :index="this.$t('tabs')[0].path">{{ this.$t('tabs')[0].name }}</el-menu-item>
      <el-submenu index="2" :popper-class="'bit-sub-menu shadow-'+theme" :active-text-color="theme=='dark'?'#FFFFFF':'#000000E6'">
        <template slot="title" class="">
          {{ this.$t('tabs')[1].name }}
        </template>
        <el-menu-item class="hc1" :index="this.$t('tabs')[1].children[0].path">{{ this.$t('tabs')[1].children[0].name }}</el-menu-item>
        <el-menu-item class="hc1" :index="this.$t('tabs')[1].children[1].path">{{ this.$t('tabs')[1].children[1].name }}</el-menu-item>
        <el-menu-item class="hc1" :index="this.$t('tabs')[1].children[2].path">{{ this.$t('tabs')[1].children[2].name }}</el-menu-item>
      </el-submenu>
      <el-menu-item :index="this.$t('tabs')[2].path">{{ this.$t('tabs')[2].name }}</el-menu-item>
      <el-menu-item :index="this.$t('tabs')[3].path">{{ this.$t('tabs')[3].name }}</el-menu-item>
    </el-menu>
<!--    <div
      class="header-tab-item fc1"
      :class="{ 'item-active': index == curTab }"
      v-for="(item, index) in tabList"
      :key="index">
      <div @click="tabChanged(item, index)" v-if="!item.children">{{ item.name }}</div>
      <div @click="tabChanged(item, index)" @mouseenter="showChildren=true" @mouseout="showChildren=false" v-else>{{ item.name }}</div>
      <img v-if="item.children" :class="{ 'rotate-180': showChildren }" :src="require(`@/assets/img/icon-dropdown-${theme}.png`)" width="16"/>
      <div class="header-tab-children" v-if="item.children" v-show="showChildren">
        <div
          class="header-tab-children-item bgc1 fc1 hc1"
          v-for="(child, cIndex) in item.children"
          :key="cIndex"
          @click="tabChanged(child, -1)">
          <label>{{ child.name }}</label>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "header-tab",
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
    curTab:function(){
      return this.$store.state.headerTab
    }
  },
  data() {
    return {
      message:'',
      msgShow: false,
      activeIndex: 1,
      showChildren: false,
      children: null,
    };
  },
  methods: {
    handleSelect(key, path) {
      const fpath = path[path.length-1]
      if(!fpath || fpath==''){
        this.$message({
          // duration: 0,
          showClose: false,
          customClass: 'bit-msg bit-msg-'+this.theme,
          message: this.$t("undoMsg")
        });
        return
      }
      this.$router.push(fpath)
    },
    showMsg(msg){
      this.message = msg
      this.msgShow = true
    },
    // tab切换回调
    tabChanged(item, index) {
      if (item.children){
        this.showChildren = !this.showChildren
        this.children = index
      }else{
        this.showChildren = false
        this.children = null

        // console.log(this.children,this.showChildren);
        // if (index == 1) {
        //   let isLogin = localStorage.getItem("isLogin")?Number(localStorage.getItem("isLogin")):0
        //   if(isLogin == 0){
        //     this.$emit("showLoginDia")
        //     return
        //   }
        // }

        this.$store.state.headerTab = index
        const path = item.path
        if(!path || path==''){
          this.$message({
            showClose: false,
            customClass: 'bit-msg bit-msg-'+this.theme,
            message: this.$t("undoMsg")
          });
          return
        }
        if (index == this.curTab) return;
        this.$router.push(path)
      }
    },
  },
};
</script>
<style lang="scss">
.light{
  .bit-menu {
    .el-submenu__icon-arrow {
      background-image: url('../assets/img/icon-dropdown-light.png');
    }
  }
}
.bit-menu{
  background-color: transparent !important;
  transition: none !important;
  .el-menu-item {
    transition: none !important;
    border-bottom-color: transparent;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;

    width: 120px;
    height: 100%;
    text-align: center;
  }

  .el-menu--horizontal > .el-menu-item {
    transition: none !important;
    border-bottom: none;
    text-decoration: none;
  }
  .el-menu-item:hover,.el-submenu__title:hover,.el-submenu:hover{
    background-color: transparent !important;
  }
  .el-menu-item,.el-submenu__title{
    border-bottom: none !important;
    transition: none !important;
  }
  .el-submenu__title{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .el-icon-arrow-down:before{
    display: none;
  }
  .el-submenu__icon-arrow {
    margin-left: 4px !important;
    color: #FFFFFF;
    width: 16px;
    height: 16px;
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('../assets/img/icon-dropdown-dark.png');
  }
}
</style>
<style lang="scss" scoped>
.header-tab {
  width: 100%;
  position: relative;
  .el-menu{
  }
  .el-menu--horizontal{
    border-bottom: none !important;
  }
  display: flex;
  justify-content: space-evenly;
  .header-tab-item {
    position: relative;
    width: 120px;
    height: 100%;
    cursor: pointer;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    img{
      margin-left: 4px;
    }
    .header-tab-children{
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 46px;
      padding: 4px 0;
      &-item{
        width: 130px;
        height: 40px;
        text-align: left;
        line-height: 40px;
        font-size: 14px;
        padding-left: 16px;
      }
    }
  }
  .item-active {
  }
}
</style>
