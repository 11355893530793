import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "./assets/theme/index.css";

import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import ElementLocale from "element-ui/lib/locale";


Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);


import VueClipboards from 'vue-clipboards';

Vue.use(VueClipboards);

const messages = {
  en: {
    message: "hello",
    login:"login",
    undoMsg: "Coming soon",
    tabs: [
      {
        name:"Home",
        path:"/",
      },
      {
        name:"Solution",
        children:[
          {
            name: "Zhongkui Data Platform",
            path:"",
          },
          {
            name: "Asset recovery",
            path:"",
          },
          {
            name: "Security audit services",
            path:"",
          }
        ]
      },
      {
        name:"News",
        path:"",
      },
      {
        name:"About Us",
        path:"",
      },
    ],
    tab: {
      case: "Assist",
      zh: "简体中文",
      en: "English"
    },
    home: {
      section1: {
        title: "Blockchain Security Solutions",
        subTitle: "Industry-leading blockchain data and security service provider",
        describe: "",
        contactUs: "Contact US",
        caseApply:""
      },
      section2: {
        title: "Zhongkui Data Platform",
        subTitle: "",
        info1: {
          title: "Billions of  address entity labels",
          subTitle: "Accurately identify address"
        },
        info2: {
          title: "Transaction tracking",
          subTitle: "Visualization of cross-chain and multichain transaction"
        },
        info3: {
          title: "Full-cycle asset recovery service",
          subTitle: "Tools for the entire process to asset recovery"
        },
        info4: {
          title: "Monitor address",
          subTitle: "7*24 hours monitoring and warning"
        },
        btn:{
          title: "Use Zhong Kui to start the investigation",
          subTitle: "Enter"
        }
      },
      section3: {
        title: "Asset recovery",
        subTitle: "",
        info1: {
          title: "Professional investigation team",
          subTitle: "A team of experts in incident investigation"
        },
        info2: {
          title: "Rich experience in asset recovery",
          subTitle: "Successfully recovered over $100 million in assets"
        },
        info3: {
          title: "Close collaboration with the judiciary",
          subTitle: "Experience in international law enforcement cooperation"
        }
      },
      section4: {
        title: "Security audit services",
        subTitle: "",
        info1: {
          title: "Comprehensive audit solution",
          subTitle: "Covers web2 and web3 security needs"
        },
        info2: {
          title: "Professional emergency incident response",
          subTitle: "Quickly mitigate the impact of exchange and DeFi security incidents"
        },
        info3: {
          title: "One-stop audit service",
          subTitle: "From code review to report release"
        }
      },
      section5: {
        title: "Professional team at your service",
        subTitle: "",
        info1: {
          title: "Industry-leading asset recovery performance",
          subTitle: ""
        },
        info2: {
          title: "Industry senior security expert",
          subTitle: ""
        },
        info3: {
          title: "Billion-level tag + AI big data platform",
          subTitle: ""
        },
        info4: {
          title: "Customized solutions",
          subTitle: ""
        }
      },

      section6: {
        info1: {
          title: "160",
          unit: "M",
          subTitle: "USD has been recovered"
        },
        info2: {
          title: "65",
          unit: "%",
          subTitle: "Success rate in hacker"
        },
        info3: {
          title: "90",
          unit: "%",
          subTitle: "Success rate in inside job"
        },
        info4: {
          title: "60",
          unit: "%",
          subTitle: "Major case market share"
        }
      },

      section7: {
        title: "News",
      },

      section8: {
        title: "Get the latest news",
        subTitle: "Contact BitJungle to be the first to receive the latest news"
      },

      section9: {
        prod:{
          title: "Solution",
          sub1: "Zhongkui Data Platform",
          sub2: "Asset recovery",
          sub3: "Security audit services",
        },
        case:{
          title: "News",
          sub1: "Case information",
        },
        us:{
          title: "About Us",
          sub1: "About",
        },
        logo: "Blockchain Security Solutions"
      },

      section10: {
        version: "@2018-2024 bitjungle.io ALL Rights Reserved"
      }
    },
    case: {
      header: {
        title: "Case assistance",
        warnMsg: "If your digital assets is stolen, Bitjungle will provide free case assessment assistance. After you submit the relevant information, we will promptly analyze your case and contact you through the provided contact details with any updates."
      },
      notify: {
        title: "Submit successfully",
        content: "If the case is accepted, we will contact you within 3 working days."
      },
      form:{
        requiredMsg: "* indicates a required field.",
        contact: {
          label:"Contact information",
          placeholder: "Email/Telegram/WeChat/Phone or others",
          requiredWarnMsg: "Please enter your contact information"
        },
        storage: {
          label: "How do you store your digital assets",
          placeholder: "Please enter the platform you use.",
          requiredWarnMsg: "Please select or fill in the storage type",
          data:[
            'Browser extension wallet',
            'HD wallet',
            'Hardware wallet',
            'Exchange',
            'Others'
          ],
          other: 'Others'
        },
        incidents: {
          label: "Have you encountered",
          placeholder: "Please enter your case types",
          requiredWarnMsg: "Please select or fill in the case type",
          data:[
            'Scam',
            'Phishing airdrop',
            'Funds stolen (wallet hacked)',
            'Ransomware attacks',
            'Authorized theft',
            'Fake wallets or malware',
            'Pig slaughter scam',
            'Others'
          ],
          other: 'Others'
        },
        loss_usd: {
          label: "Amount of loss (USD)",
          placeholder: "Please enter the amount of loss",
          requiredWarnMsg: "Please enter the amount of loss"
        },
        loss_addr: {
          label: "Stolen address/transaction hash",
          placeholder: "Please enter the stolen address/transaction hash",
          requiredWarnMsg: "Please enter the stolen address/transaction hash"
        },
        remark: {
          label: "Additional Notes",
          placeholder: "Please enter your additional Notes"
        },
        commitBtn: 'Submit'
      },
    },
    ...zhLocale,
  },
  zh: {
    message: "你好",
    login:"登录",
    undoMsg: "敬请期待",
    tabs: [
      {
        name:"首页",
        path:"/",
      },
      {
        name:"产品服务",
        children:[
          {
            name:"数字资产追回",
            path:"",
          },
          {
            name:"钟馗大数据平台",
            path:"",
          },
          {
            name:"安全审计服务",
            path:"",
          }
        ]
      },
      {
        name:"案例新闻",
        path:"",
      },
      {
        name:"关于我们",
        path:"",
      },
    ],
    tab: {
      case: "案件援助",
      zh: "简体中文",
      en: "English"
    },
    home: {
      section1: {
        title: "守护区块链生态安全",
        subTitle: "业界领先的链上数据和安全服务提供商",
        describe: "数字资产溯源｜安全审计服务｜链上追踪",
        contactUs: "在线咨询",
        caseApply:"案件提交"
      },
      section2: {
        title: "钟馗大数据平台",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "亿级标签覆盖",
          subTitle: "精准识别交易地址身份"
        },
        info2: {
          title: "交易链路追踪",
          subTitle: "跨链多币资金流向可视化"
        },
        info3: {
          title: "一站式追回能力",
          subTitle: "线索发掘到资产挽回全流程工具"
        },
        info4: {
          title: "地址交易监控",
          subTitle: "7*24小时监控和预警"
        },
        btn:{
          title: "用钟馗 破大案",
          subTitle: "立即体验"
        }
      },
      section3: {
        title: "数字资产追回",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "专业破案团队",
          subTitle: "行业资深安全专家团队为您服务"
        },
        info2: {
          title: "追回经验丰富",
          subTitle: "成功追回10亿被盗资产"
        },
        info3: {
          title: "执法深度合作",
          subTitle: "同多地公安合作熟悉案件侦破全流程"
        }
      },
      section4: {
        title: "安全审计服务",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "全方位审计解决方案",
          subTitle: "覆盖传统与区块链安全需求"
        },
        info2: {
          title: "专业应急响应",
          subTitle: "迅速减轻交易所与DeFi安全事件影响"
        },
        info3: {
          title: "一站式审计服务",
          subTitle: "从代码审核到报告出具"
        }
      },
      section5: {
        title: "专业团队为您服务",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          title: "业界领先的资产追回表现",
          subTitle: ""
        },
        info2: {
          title: "行业资深安全专家",
          subTitle: ""
        },
        info3: {
          title: "亿级标签库+AI大数据平台",
          subTitle: ""
        },
        info4: {
          title: "定制化解决方案",
          subTitle: ""
        }
      },

      section6: {
        info1: {
          title: "1.6",
          unit: "亿",
          subTitle: "累计挽回美金"
        },
        info2: {
          title: "65",
          unit: "%",
          subTitle: "外部黑客破案率"
        },
        info3: {
          title: "90",
          unit: "%",
          subTitle: "内部作案破案率"
        },
        info4: {
          title: "60",
          unit: "%",
          subTitle: "重大案件破获率（行业第一）"
        }
      },

      section7: {
        title: "成功案例与热点资讯",
        info1: {
          title: "1.最新解读：两高将虚拟资产交易纳入洗钱方式，谨慎…",
          subTitle: "2024年8月19日，最高人民法院与最高人民检察院联合发布了最新的司法解释，将虚拟资产交易明确列为洗钱手段之一…"
        },
      },

      section8: {
        title: "获取最新动态",
        subTitle: "与比特丛林建立联系，抢先获取最新动态"
      },

      section9: {
        prod:{
          title: "产品服务",
          sub1: "钟馗大数据平台",
          sub2: "数字资产追回",
          sub3: "报案文书生成",
        },
        case:{
          title: "成功案例",
          sub1: "成功案例",
          sub2: "视频支持",
        },
        us:{
          title: "关于我们",
          sub1: "公司简介",
        },
        logo: "区块链生态安全守护者",
      },

      section10: {
        version: "@2018-2024 bitjungle.io ALL Rights Reserved"
      }
    },
    case: {
      header: {
        title: "案件提交",
        warnMsg: "如果您的加密货币被盗，Bitjungle 将提供免费的案件评估援助服务。在您提交相关信息之后我们会尽快分析您的案件，并在有最新进展时，通过您提供的联系方式与您联系。"
      },
      notify: {
        title: "提交成功",
        content: "如案件被受理，我们将在3个工作日内联系您"
      },
      form:{
        requiredMsg: "* 表示必填",
        contact: {
          label:"联系方式",
          placeholder: "邮件/ Telegram/ 微信/ 电话或其他联系方式",
          requiredWarnMsg: "请输入联系方式"
        },
        storage: {
          label: "您以何种方式存储数字资产",
          placeholder: "请输入其他平台类型",
          requiredWarnMsg: "请选择或填写平台类型",
          data:[
            'MetaMask 钱包',
            'HD钱包',
            '硬件钱包',
            '交易所',
            '其他'
          ],
          other: '其他'
        },
        incidents: {
          label: "您是否遭遇",
          placeholder: "请输入其他案件类型",
          requiredWarnMsg: "请选择或填写案件类型",
          data:[
            '诈骗',
            '钓鱼空头',
            '资金被盗（钱包被黑）',
            '勒索攻击',
            '授权盗币',
            '假冒钱包或恶意软件',
            '杀猪盘',
            '其他'
          ],
          other: '其他'
        },
        loss_usd: {
          label: "损失金额（美元）",
          placeholder: "请输入损失金额",
          requiredWarnMsg: "请输入损失金额"
        },
        loss_addr: {
          label: "被盗地址/交易哈希",
          placeholder: "请输入被盗的地址或交易哈希",
          requiredWarnMsg: "请输入被盗地址或交易哈希"
        },
        remark: {
          label: "补充说明",
          placeholder: "请输入其他情况说明"
        },
        commitBtn: '提交'
      },
    },
    ...zhLocale,
  },
};
const i18n = new VueI18n({
  locale: localStorage.getItem("lang")||"zh", // set locale
  messages, // set locale messages
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

router.beforeEach((to, from, next)=>{
  // const accessToken = localStorage.getItem("accessToken");
  // if(!accessToken && to.path!="/"){
  //   next({ path: '/' })
  // }else{
    next()
  // }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
